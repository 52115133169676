import React, { Suspense } from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
import GlobalLoading from 'components/GlobalLoading';
import RouteGuard from './Route';

// TODO: In the next iteration make it more dynamic.
// Redis can be used for serving the page definition
const pages = [
  {
    id: 1,
    name: 'Home',
    path: '/',
    componentPath: 'home',
    routePatth: '',
    isPrivate: true,
    roles: [],
    exact: true,
    layout: '',
  },
  {
    id: 2,
    name: 'Login',
    componentPath: 'login',
    path: '/login',
    isPrivate: false,
    roles: [],
    layout: 'fullscreen',
  },
  {
    id: 3,
    name: 'Registration',
    componentPath: 'register',
    path: '/registration',
    isPrivate: false,
    roles: [],
    layout: '',
  },
  {
    id: 4,
    name: 'ForgotPassword',
    componentPath: 'forgot-password',
    path: '/forgot-password',
    isPrivate: false,
    roles: [],
    layout: 'fullscreen',
  },
  {
    id: 5,
    name: 'ResetPassword',
    componentPath: 'reset-password',
    path: '/reset-password',
    isPrivate: false,
    roles: [],
    layout: 'fullscreen',
  },
  {
    id: 6,
    name: 'MyAccount',
    componentPath: 'my-account',
    path: '/account',
    isPrivate: true,
    roles: [],
  },
  {
    id: 7,
    name: '',
    componentPath: 'NotFound',
    path: '/404',
    isPrivate: false,
    roles: [],
    layout: '',
  },
  {
    id: 8,
    name: 'Patient',
    componentPath: 'patient',
    path: '/patient/:id',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 9,
    name: 'Clinic List',
    componentPath: 'clinic-list',
    path: '/clinic',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 10,
    name: 'Care List',
    componentPath: 'care-list',
    path: '/care',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 11,
    name: 'Finance List',
    componentPath: 'finance-list',
    path: '/finance',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 12,
    name: 'Claim List',
    componentPath: 'claim-list',
    path: '/claim',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 13,
    name: 'Claim',
    componentPath: 'claim',
    path: '/claim-detail/:id',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 14,
    name: 'Onboard Patient',
    componentPath: 'onboard-patient',
    path: '/onboard-patient',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 15,
    name: 'Landing Page',
    componentPath: 'landing-page',
    path: '/landing-page',
    isPrivate: true,
    roles: [],
    layout: 'fullscreen',
  }
];
const LoadingMessage = () => <GlobalLoading delay={0}>Loading</GlobalLoading>;

export default function Routes() {
  return (
    <Suspense fallback={<LoadingMessage />}>
      <Switch>
        {pages.map((page) => (
          <RouteGuard
            key={page.id}
            path={page.path}
            layout={page.layout}
            component={React.lazy(() => import(`pages/${page.componentPath}`))}
            isPrivate={page.isPrivate}
            exact={page.exact}
          />
        ))}
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}
