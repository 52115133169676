import { cache } from 'Cache';
import { getAuthToken, clearToken, setAuthInfo } from 'utils/auth';

import { applicationInformation } from 'applicationInfo';
/**
 * @description
 * This fetch request is responsible for refreshing the JWT
 * token on the server. We need to send the request outside
 * of Apollo Client in order to provide different configuration.
 * Apollo Client will have Bearer token added automatically
 * to the request which is not needed for the token refresh.
 *
 * @returns Promise
 */
export const refreshToken = () => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/graphql`);

  const body = {
    operationName: 'refreshToken',
    variables: {
      accessToken: getAuthToken(),
    },
    query:
      'mutation refreshToken($accessToken: String!) {\n refreshToken(accessToken: $accessToken) {aspNetUserId\n refreshToken\naccessToken {token\n expiresIn\n}\n}\n}',
  };

  return fetch(url.toString(), {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...applicationInformation,
    },
    body: JSON.stringify(body),
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((json) => {
      if (json?.data?.refreshToken) {
        setAuthInfo(json.data.refreshToken);
      } else if (Array.isArray(json?.errors) && json?.errors.length > 0) {
        if(window.location.pathname != '/login' && window.location.pathname != '/forgot-password' && window.location.pathname.indexOf('/reset-password') == -1 ){
          handleRefreshTokenErrors(json.errors);
          window.location.href = '/';
        }
      }
    })
    .catch((error) => {
      // If we encounter error with token refresh
      // We need to log user out. There is no way token can be restored.
      if(window.location.pathname != '/login' && window.location.pathname != '/forgot-password' && window.location.pathname.indexOf('/reset-password') == -1 ){
        onErrorClearToken();
        window.location.href = '/';
      }
    })
    .finally(() => {});
};

const onErrorClearToken = async () => {
  try {
    const url1 = new URL(`${process.env.REACT_APP_API_URL}/graphql`);
    const body1 = {
      operationName: 'exitSoboMode',
      query:
        'mutation exitSoboMode {\n exitSoboMode\n}',
    };
    await fetch(url1.toString(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...applicationInformation,
      },
      body: JSON.stringify(body1),
      credentials: 'include',
    });

    const url2 = new URL(`${process.env.REACT_APP_API_URL}/graphql`);
    const body2 = {
      operationName: 'logout',
      query:
        'mutation logout {\n logout\n}',
    };
    await fetch(url2.toString(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...applicationInformation,
      },
      body: JSON.stringify(body2),
      credentials: 'include',
    });
  }catch(error){

  }
  cache.reset();
  clearToken();
};

const handleRefreshTokenErrors = (gqlErrors: any[]) => {
  const result = gqlErrors?.find(
    (error) =>
      error?.extensions?.code === 'UNAUTHENTICATED' &&
      error.message.startsWith('Unable to refresh token')
  );

  // If result is not null we know that refresh token was not successful
  // This might be due to expired refreshToken value, missing cookie.
  // Do the cleanup in the application by reseting the cache and clearing the token
  if (result != null) {
    onErrorClearToken();
  }
};
