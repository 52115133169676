import React, { createContext, useContext, useState } from 'react';

// Operations
import { useInitialLoad } from 'operations/queries/initalLoad';

// Root
import themeBuilder from 'buildMuiTheme';

// Material UI
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';


interface Task {
  taskId: string;
  taskName: string;
}

interface LinkedTasksContextType {
  linkedTasks: Task[];
  setLinkedTasks: React.Dispatch<React.SetStateAction<Task[]>>;
}

const SiteContext = createContext({});
const LinkedTasksContext = createContext<LinkedTasksContextType | null>(null);

const LINED_TASKS_LOCAL_STORAGE_KEY = 'upgrd-linkd-tasks';

interface Props { }

const SiteProvider: React.FC<Props> = (props) => {
  // Operations
  const { site, loading } = useInitialLoad();
  const [linkedTasks, setLinkedTasks] = useState<Task[]>(() => {
    const savedTasks = localStorage.getItem(LINED_TASKS_LOCAL_STORAGE_KEY);
    return savedTasks ? (JSON.parse(savedTasks) as Task[]) : [];
  });

  if (loading) {
    return null;
  }

  return (
    <MuiThemeProvider theme={themeBuilder(site?.settings?.selectedTheme)}>
      <SiteContext.Provider value={site}>
        <LinkedTasksContext.Provider value={{ linkedTasks, setLinkedTasks }}>
          {props.children}
        </LinkedTasksContext.Provider>
      </SiteContext.Provider>

    </MuiThemeProvider>
  );
};

export const useSiteContext = () => {
  const context = useContext(SiteContext);

  if (context === null) {
    throw new Error('useSiteContext must be used within SiteContext.');
  }

  return context;
};

// Hook to use LinkedTasks context with local storage
export const useLinkedTasks = (): LinkedTasksContextType => {
  const context = useContext(LinkedTasksContext);

  if (!context) {
    throw new Error('useLinkedTasks must be used within LinkedTasksContext.');
  }

  return {
    linkedTasks: context.linkedTasks,
    setLinkedTasks: (newLinkedTasks) => {
      localStorage.setItem(LINED_TASKS_LOCAL_STORAGE_KEY, JSON.stringify(newLinkedTasks));
      context.setLinkedTasks(newLinkedTasks)
    }
  }
};

export { SiteContext, LinkedTasksContext };

export default SiteProvider;
