import {
  ApolloLink,
  Operation,
  Observable,
  NextLink,
  FetchResult,
} from '@apollo/client';
import { getAuthToken } from 'utils/auth';
import { refreshToken } from 'utils/graphql/refreshTokenRequest';
import { isTokenValid } from 'services/auth.service';
import { OperationQueuing } from './operationQueuing';

/**
 * @description
 * ApolloClient link responsible for refreshing the JWT token.
 * This only applies to `quries` and `mutation` as `subscriptions`
 * use different mechanism for token refresh specified 
 * in the `/utils/apolloClient.tsx` file.
 */
export class JwtRefreshLink extends ApolloLink {
  private fetching: boolean;

  private queue: OperationQueuing;
  
  constructor() {
    
    super();

    this.fetching = false;
    this.queue = new OperationQueuing();
  }

  isTokenValidOrUndefined() {
    const token = getAuthToken();

    if (!token) {
      return true;
    }

    const isValid = isTokenValid(token);

    if (isValid) {
      return true;
    }
    return false;
  }

  request(
    operation: Operation,
    forward: NextLink
  ): Observable<FetchResult> | null {
    if (typeof forward !== 'function') {
      throw new Error(
        '[JWT Refresh Link]: JWT Refresh Link is a non terminating link and should not be the last in the composed chain'
      );
    }

    // If token does not exist, this could mean that this is not authenticated user,
    // Or the token is not expired - work as normal
    if (this.isTokenValidOrUndefined()) {
      return forward(operation);
    }

    if (!this.fetching) {
      this.fetching = true;
      const refreshTokenRequest = refreshToken();
      refreshTokenRequest.finally(() => {
        this.fetching = false;

        this.queue.consumeQueue();
      });
    }
    return this.queue.enqueueRequest({ operation, forward });
  }
}
