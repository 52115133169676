import React, {useContext,useState} from 'react';
import { useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { SoboContact } from 'interfaces';
import { Hidden } from '@material-ui/core';
import { LinkedAccount } from '../../operations/queries/getLinkedAccounts';
import { useSetPatientChartDisconnectReason } from 'operations/mutations/setPatientChartDisconnectReason';

import { SiteContext } from 'utils/SiteProvider';

interface Props {
  contact: LinkedAccount;
  handleSetSobo: (contact: LinkedAccount) => void;
}

// interface Props {
//   contact: any;
//   handleSetSobo: (contact: any) => void;
// }

const SoboAccountListItem: React.FC<Props> = ({ contact, handleSetSobo }) => {

  const { mutate: setPatientChartDisconnectReason } = useSetPatientChartDisconnectReason();
  const site:any = useContext(SiteContext);
  const location = useLocation();
  const [isDisabled, setDisabled] = useState<boolean>(false);

  const handleSoboClick = () => {
    if(location?.pathname?.includes('/patient/')) {
      setPatientChartDisconnectReason({
        variables: {
          input: {
            accountId: site.session.user?.id,
            code: 'LOGOUT'
          }
        }
      }).then(() => {
        handleSetSobo(contact);
        setDisabled(true);
      });
    } else {
      handleSetSobo(contact);
      setDisabled(true);
    }
  }

  return (
    <>
      <ListItem>
        <Hidden smDown>
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
        </Hidden>
        <ListItemText primary={`${contact.name}`} secondary={contact.code} />
        <Button color="default" variant="contained" onClick={handleSoboClick} disabled={isDisabled}>Select</Button>
      </ListItem>
      <Divider />
    </>
  );
};

export default SoboAccountListItem;
