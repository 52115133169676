import { Resolvers, gql, ApolloCache } from '@apollo/client';

export const typeDefs = gql`
  type Message {
    id: ID
    text: String
    variant: String
    severity: String
  }

  type CartMessageAttribute {
    name: String
    value: String
  }
  type AddToCartMessage {
    id: ID
    name: String
    img: Image
    price: Int
    quantity: Int
    attributes: [CartMessageAttribute]
    seoName: String
  }
  extend type User {
    id: ID
    deletedAt: String
    emailConfirmed: Boolean
    exp: String
    iat: String
  }
  # TODO: Get proper type
  type SelectedShipping {
    id: ID
  }
  extend type Cart {
    selectedShipping: SelectedShipping
    orderBillingAddress: Address
    orderShippingAddress: Address
  }
  extend type Query {
    isLoggedIn: Boolean
    user: User
    mediumAttentionMessages: Message
    lowAttentionMessages: Message
    highAttentionMessages: Message
    addToCartMessages: AddToCartMessage
  }
`;

type ResolverFn = (
  parent: any,
  args: any,
  { cache }: { cache: ApolloCache<any> }
) => any;

interface ResolverMap {
  [field: string]: ResolverFn;
}

interface AppResolvers extends Resolvers {
  // we will update this with our app's resolvers
  Cart: ResolverMap;
}

export const resolvers: AppResolvers = {
  Cart: {
    orderBillingAddress: (cart: any) => {
      const { addresses, orderBillingAddressId } = cart;
      if (!!addresses?.length && orderBillingAddressId) {
        const billingAddress = addresses.find(
          (address: any) => address.id === orderBillingAddressId
        );
        return billingAddress || null;
      }

      return null;
    },
    orderShippingAddress: (cart: any) => {
      const { addresses, orderShippingAddressId } = cart;
      if (!!addresses?.length && orderShippingAddressId) {
        const shippingAddress = addresses.find(
          (address: any) => address.id === orderShippingAddressId
        );

        return shippingAddress;
      }

      return null;
    },
  },
};
