import {} from '@material-ui/core';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';

import set from 'lodash/set';
import merge from 'lodash/merge';
import { ThemeOverride } from './interfaces/theme';

const themeBuilder = (themeObject?: any, options?: ThemeOverride[]) => {
  const option: ThemeOverride[] = options || [];

  const secondaryRed = '#FDBB56';
  const secondaryGreen = '#FDBB56'
  const secondarySky = '#47C3CD'
  const secondaryPink = '#F05165'
  const secondaryYellow = '#FDBB56'
  const secondaryPuple = '#BAA1CD'
  const secondaryBrown = '#9F8170'
  const secondaryMud = '#567275'
  const secondaryBlue = '#416FB6'

  const created = option.reduce((prev, curr) => {
    set(prev, curr.name, curr.value);
    return prev;
  }, {} as any);
  const theme: ThemeOptions = {
    breakpoints: {
      values: {
        xs: themeObject.xsBreakpoint,
        sm: themeObject.smBreakpoint,
        md: themeObject.mdBreakpoint,
        lg: themeObject.lgBreakpoint,
        xl: themeObject.xlBreakpoint,
      },
    },
    palette: {
      background: {
        default: themeObject.background,
        paper: themeObject.panelBackground,
      },
      primary: {
        main: themeObject.colorPrimary,
        dark: themeObject.colorPrimary,
        contrastText: themeObject.colorSecondary
      },
      secondary: {
        main: themeObject.colorSecondary,
        dark: themeObject.colorSecondary,
        contrastText: themeObject.colorPrimary
      },
      text: {
        primary: themeObject.textPrimary,
        secondary: themeObject.textSecondary,
      },
      warning: {
        main: themeObject.warning,
      },
      success: {
        main: themeObject.success,
      },
      error: {
        main: themeObject.error,
      },
      info: {
        main: themeObject.info,
      },
    },
    typography: {
      fontFamily: themeObject.fontFamily,
      h1: {
        fontSize: `${themeObject.h1}rem`,
        fontFamily: themeObject.h1Font || themeObject.fontFamily,
      },
      h2: {
        fontSize: `${themeObject.h2}rem`,
        fontFamily: themeObject.h2Font || themeObject.fontFamily,
      },
      h3: {
        fontSize: `${themeObject.h3}rem`,
        fontFamily: themeObject.h3Font || themeObject.fontFamily,
      },
      h4: {
        fontSize: `${themeObject.h4}rem`,
        fontFamily: themeObject.h4Font || themeObject.fontFamily,
      },
      h5: {
        fontSize: `${themeObject.h5}rem`,
        fontFamily: themeObject.h5Font || themeObject.fontFamily,
      },
      h6: {
        fontSize: `${themeObject.h6}rem`,
        fontFamily: themeObject.h6Font || themeObject.fontFamily,
      },
      body1: {
        fontSize: `${themeObject.body1}rem`,
        fontFamily: themeObject.body1Font || themeObject.fontFamily,
      },
      body2: {
        fontSize: `${themeObject.body2}rem`,
        fontFamily: themeObject.body2Font || themeObject.fontFamily,
      },
      button: {
        fontFamily: themeObject.fontFamily,
      },
    },
    shape: {
      borderRadius: 0,
    },
    overrides: {
      MuiSwitch: {
        switchBase: {
          "&$disabled": {
            color: "yellow"
          },
          "&$disabled + $track": {
            backgroundColor: "green",
            opacity: 0.5
          }
        },
        track: {
          backgroundColor: 'black !important'
        }
      }
    },
  };

  // Default theme needed in case the generate theme has undefined values.
  const defaultTheme = createTheme();
  const mergedTheme = merge(theme || {}, created);
  return createTheme(mergedTheme);
};

export default themeBuilder;
