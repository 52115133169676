import { gql } from '@apollo/client';

export const GET_LINKED_ACCOUNTS = gql`
  query soboAccountList($accountId: ID, $input: SoboAccountListInput) {
    soboAccountList(accountId: $accountId, input: $input) {
      pageInfo {
        resultsReturned
        sort
        top
        skip
      }
      nodes {
        id
        code
        email
        name
        hasSoboCart
      }
    }
  }
`;

export interface GetLinkedAccountsData {
  getLinkedAccounts: LinkedAccountsData;
}

export interface LinkedAccountsData {
  linkedAccounts: LinkedAccount[];
  recordCount: number;
}

export interface LinkedAccount {
  code: string;
  email: string | null;
  name: string;
  id: string;
  phoneNumber: string | null;
  userId: number | null;
  hasSoboCart:boolean | null;
}
