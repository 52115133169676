import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import {
  getToken, tokenKey, decodeToken, isTokenValid,
} from '../services';
import { clearToken } from 'utils/auth';
// import { store } from './../shared/store';
// import { API_REQUEST_FAILED } from './../shared/actions/global.actions';
import { history } from '../App';
import { applicationInformation } from 'applicationInfo';

declare global {
  interface Window {
    refreshingToken: boolean;
  }
}

const _getToken = () => new Promise((resolve, reject) => {
  // Currently Refreshing...
  if (window.refreshingToken) {
    let count = 0;
    const interval = setInterval(() => {
      if (!window.refreshingToken) {
        const token = sessionStorage.getItem(tokenKey);
        // *** RESOLVE ***
        resolve(token);
        clearInterval(interval);
      }
      count++;
      if (count >= 60) {
        reject('Refresh token timed out');
        clearInterval(interval);
      }
    }, 500);
  } else {
    try {
      const currentToken = getToken();
      if (currentToken == undefined) throw 'Token not found. User may not be signed in.';

      const token = currentToken || '';
      // let now = new Date().getTime();
      const decodedToken = decodeToken(token);
      // Fresh
      if (isTokenValid()) {
        // *** RESOLVE ***
        resolve(token);
      }
      // Expired
      else {
        window.refreshingToken = true;
        sessionStorage.setItem(tokenKey, 'Refreshing...');

        return _refreshToken(currentToken).then((newToken: string) => {
          // newToken = this._buildToken(newToken);
          sessionStorage.setItem(tokenKey, JSON.stringify(newToken));

          window.refreshingToken = false;
          // *** RESOLVE ***
          resolve(newToken);
        });
      }
    } catch (error) {
      throw error;
    }
  }
});

export function fetchStatusHandler(response: AxiosResponse) {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  // Show toast message when error occurs
  // if (response.status >= 400 && response.status != 401) {
  // 	store.dispatch({
  // 		type: API_REQUEST_FAILED,
  // 		payload: {
  // 			message: `Something went wrong. ${response.statusText}`
  // 		}
  // 	});
  // }

  throw new Error(response.statusText);
}

export const accessTokenInterceptor = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  let token = null;
  try {
    token = await _getToken();
  } catch (error) {
    // Redirect to login page no need to do anything.
    token = null;
  }
  if (!token && window.location.pathname != '/login' && window.location.pathname != '/forgot-password' && window.location.pathname.indexOf('/reset-password') == -1 ) {
    await onErrorClearToken();
    window.location.href = '/login';
  }
  if (token != null) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onErrorClearToken = async () => {
  try {
    const url1 = new URL(`${process.env.REACT_APP_API_URL}/graphql`);
    const body1 = {
      operationName: 'exitSoboMode',
      query:
        'mutation exitSoboMode {\n exitSoboMode\n}',
    };
    await fetch(url1.toString(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...applicationInformation,
      },
      body: JSON.stringify(body1),
      credentials: 'include',
    });

    const url2 = new URL(`${process.env.REACT_APP_API_URL}/graphql`);
    const body2 = {
      operationName: 'logout',
      query:
        'mutation logout {\n logout\n}',
    };
    await fetch(url2.toString(), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...applicationInformation,
      },
      body: JSON.stringify(body2),
      credentials: 'include',
    });
  }catch(error){

  }
  // Note: Possibly need to include a cache.reset(); call as shown in refreshTokenRequest.ts
  clearToken();
};

const _refreshToken = (token: string) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Content-Length': token.length,
    },
    body: token,
  };
  return axios
    .post('/api/v1/refreshToken', options)
    .then((response) => response)
    .catch((error: any) => error);
};

export const responseRefreshTokenInterceptor = (error: AxiosError) => {
  const originalRequest: Partial<AxiosRequestConfig & { _retry: boolean }> = error.config;
  const serverCallUrl = new URL(originalRequest.url || '');
  const status = error.response && error.response.status;
  const { headers } = error.request;

  // TODO: Check if we have refresh token
  if (
    'token-expired' in headers
    && headers['token-expired'] === 'true'
    && status === 401
    && !originalRequest._retry
  ) {
    // Try to refresh the token
    // let token = await refreshAccessToken();
    // setAccessToken()
    // Redirect to login page
    history.push('/login');
    // originalRequest._retry = true;
    // originalRequest.headers.Authorization = `Bearer ${token}`;

    // return axios(originalRequest);
  }

  return Promise.reject(error);
};
