import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from 'components/Header';
// import Footer from 'components/Footer';
import Copyright from 'components/Footer/Copyright';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(22),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '80vh',
  },
}));

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root} maxWidth="xl">
        <Header menuJson={null} />
        <main className={classes.main}>{children}</main>
      </Container>
      {/* <Footer /> */}
      <Copyright />
    </>
  );
};

export default AuthLayout;
