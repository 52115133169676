import { gql, useQuery } from '@apollo/client';

export const GET_API_VERSION = gql`
  query getApiVersion {
    getApiVersion
  } 
`;

export const useGetApiVersion = (input: any, noteInput: any) => {
  const { loading, error, data } = useQuery(GET_API_VERSION, {variables: {}});

  return {loading, error, data};
}