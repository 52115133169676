/**
 * Formats a given phone number into a standardized format of "(XXX) XXX-XXXX".
 *
 * @param {number|string} phoneNumber - The phone number to format, provided as either number or string.
 * @returns {string} The formatted phone number if it consists of exactly 10 digits, otherwise returns the input as a string.
 */

export const formatPhoneNumber = (phoneNumber: number | string): string => {
    let number = phoneNumber;
    if (typeof phoneNumber === 'number') {
        number = number.toString();
    }
    const cleaned = ('' + number).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return number.toString();
}
