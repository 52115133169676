import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    //[theme.breakpoints.up('md')]: {
      width: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      overflow: 'hidden'
    //},
    // [theme.breakpoints.down('sm')]: {
    //   paddingTop: theme.spacing(4)
    // },
    
  },
}));
interface Props {
  children: React.ReactNode;
}

const FullscreenLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="lg">
      <>{children}</>
    </Container>
  );
};

export default FullscreenLayout;
