import React, { useContext, useEffect } from 'react';
import { Route, Redirect,useLocation } from 'react-router-dom';
import noop from 'lodash/noop';

import { SiteContext } from 'utils/SiteProvider';

import DefaultLayout from 'pages/_layouts/default';
import AuthLayout from 'pages/_layouts/auth';
import InnerLayout from 'pages/_layouts/inner';
import CheckoutLayout from 'pages/_layouts/checkout';
import FullscreenLayout from 'pages/_layouts/fullscreen';
import { messageMutations } from 'operations/mutations/messages';

const layouts = new Map();
layouts.set('default', DefaultLayout);
layouts.set('auth', AuthLayout);
layouts.set('inner', InnerLayout);
layouts.set('checkout', CheckoutLayout);
layouts.set('fullscreen', FullscreenLayout);

interface Props {
  component: any;
  isPrivate?: boolean;
  path?: string;
  exact?: boolean;
  layout?: string;
  location?: any;
  recursive?: boolean
  getPropsFromPage?: (title: string) => void;
}

const RouteWrapper: React.FC<Props> = ({
  component: Component,
  isPrivate,
  layout,
  location,
  recursive,
  getPropsFromPage,
  ...rest
}: Props) => {
  const site:any = useContext(SiteContext);
  
  // const authenticated = currentUser && currentUser.exp > Date.now() / 1000;
  const authenticated = site?.session?.isLoggedIn;
  /** Redirect user to Login page if he tries to access a private route
   * without authentication
   */
  let watchPath = useLocation();

  if (isPrivate && !authenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  if (isPrivate && !site?.session?.sobo?.accountId && window.location.pathname != "/landing-page") {
    return <Redirect to="/landing-page" />
  }

  let Layout = AuthLayout;
  if (layout && layouts.has(layout)) {
    Layout = layouts.get(layout);
  }

  
  useEffect(() =>{

    messageMutations.popAddToCartMessage();

  },[watchPath.pathname])


  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} getPropsFromPage={getPropsFromPage} />
        </Layout>
      )}
    />
  );
};

RouteWrapper.defaultProps = {
  getPropsFromPage: noop,
};
export default RouteWrapper;
