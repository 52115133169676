import { gql } from '@apollo/client';

export const SHIPPING_BY_CARRIERS = gql`
  query shippingbyCarrier($id: String) {
    shippingbyCarrier(id: $id) {
      id
      name
      code
      baseAmount
      discount
      total
      shippingRateTypeName
      shippingRateTypeCode
    }
  }
`;
