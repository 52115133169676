import { gql } from '@apollo/client';

export const GET_COUNTRY = gql`
  query country($id: String!) {
    country(id: $id) {
      id
      name
      state {
        id
        name
        code
      }
    }
  }
`;

export const GET_COUNTRY_BY_CODE = gql`
  query countryByCode($code: String!) {
    countryByCode(code: $code) {
      id
      name
      state {
        id
        name
        code
      }
    }
  }
`;

