/**
 * For the authentication service we are using axios instead of
 * api module. We don't need any tokens added for the authentication.
 *
 */
import axios, { AxiosResponse, AxiosError } from 'axios';
import { generateSession } from '../utils/generate-session';

import { api } from './api';
import {
  LoginSuccessEvent,
  LoginFailEvent,
  DecodedToken,
  ChangePasswordResponse,
  RegistrationSuccessEvent,
  RegistrationFailEvent,
} from '../interfaces';

import { applicationInformation } from 'applicationInfo';

export const tokenKey = 'token';
export const refreshTokenKey = '_rt';

export const storeTokensInStorage = (payload: LoginSuccessEvent): void => {
  window.sessionStorage.setItem(tokenKey, payload.accessToken.token);
  window.sessionStorage.setItem(refreshTokenKey, payload.refreshToken);
};

export const loginRequest = (
  userName: string,
  password: string,
): Promise<LoginSuccessEvent | LoginFailEvent> => axios
  .post(
    'api/v1/account/login',
    {
      username: userName,
      password,
    },
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'ev-sessionid': generateSession(),
        Accept: 'application/json, text/plain, */*',
      },
    },
  )
  .then((res: AxiosResponse) => res.data)
  .catch((error: AxiosError) => {
    console.log('error ', error);
    return error.response && error.response.data;
  });

/**
 *
 * @param accessToken String
 * @param refreshToken String
 *
 * Using axios directly to avoid adding Auth header to the refresh token
 */
export const refreshTokenRequest = (
  accessToken: string,
  refreshToken: string,
): Promise<LoginSuccessEvent | undefined> => axios
  .post('/api/v1/account/refreshToken', {
    accessToken,
    refreshToken,
  })
  .then((res: AxiosResponse<LoginSuccessEvent>) => {
    if (res != null) {
      return res.data;
    }
  });

export const getToken = (): string | null => {
  let token = null;
  if ('sessionStorage' in window) {
    token = sessionStorage.getItem(tokenKey);
  }

  return token;
};

export const getRefreshToken = (): string | null => {
  let refreshToken = null;
  if ('sessionStorage' in window) {
    refreshToken = sessionStorage.getItem(refreshTokenKey);
  }

  return refreshToken;
};

export const decodeToken = (token: string | null): DecodedToken | null => {
  if (!token) {
    return null;
  }

  try {
    const tokenString = token.split('.')[1];
    const base64Token = tokenString.replace('-', '+').replace('_', '/');
    const decoded = JSON.parse(window.atob(base64Token));
    return decoded;
  } catch (error) {
    // throw new Error('Invalid token or user is not logged in');
    console.error('Invalid token or user is not logged in');
    return null;
  }
};

export const isTokenValid = (t?: string): boolean => {
  let flag = false;

  let token = null;
  if (!t) {
    token = getToken();
  } else {
    token = t;
  }

  if (token == null) {
    return flag;
  }

  let decodedToken = null;

  try {
    decodedToken = decodeToken(token);
    if (decodedToken) {
      // console.info(
      //   'Token expires in: ',
      //   ((decodedToken.exp - Date.now() / 1000) / 60).toFixed(2),
      // );
      const { exp } = decodedToken;
      flag = exp > Date.now() / 1000;
      // const { setTimeout } = window;
      // try{
      //   let expiryInMiliseconds = 0;
      //   if (typeof decodedToken?.exp === 'number') {
      //       expiryInMiliseconds = (new Date(decodedToken.exp * 1000).getTime() - new Date().getTime());
      //       expiryInMiliseconds = ((expiryInMiliseconds + ((decodedToken.exp - decodedToken.iat)*1000)) - 120000); // Change to be expiryInMiliseconds + the total token expiry length - 120000
      //   }
        
      //   setTimeout(() => {
      //     token = getToken();
      //     decodedToken = decodeToken(token);
      //     if (typeof decodedToken?.exp === 'number') {
      //       expiryInMiliseconds = (new Date(decodedToken.exp * 1000).getTime() - new Date().getTime());
      //     }
          
      //     if ((expiryInMiliseconds-120000) <= 0){
      //       document.dispatchEvent(new Event("refresh-token"));
      //     }
      //   }, expiryInMiliseconds); // Event for logout notification will fire 2 minutes prio to token expiry
      // } catch (error) {}

      // TODO: Temporary to enforce inactivity timeout; logout at twice the amount of inactivity as access token
      if ((decodedToken.exp - Date.now() / 1000) <= -(decodedToken.exp - decodedToken.iat)) {
        const url = new URL(`${process.env.REACT_APP_API_URL}/graphql`);
        console.log("!!!!");
        const body = {
          operationName: 'logout',
          query:
            'mutation logout {\n logout\n}',
        };

        fetch(url.toString(), {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            ...applicationInformation,
          },
          body: JSON.stringify(body),
          credentials: 'include',
        })
      }
    }
  } catch (error) {
    console.error('Unable to decode token:', error);
  }
  return flag;
};

/**
 *
 * Those methods are from the Mint environment. Not used on the PRS
 * and if needed should be rewriten using new architecture rather
 * than being calls from the front-end.
 *
 * */
export const logout = (cartId?: string): Promise<boolean> => {
  if ('sessionStorage' in window) {
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(refreshTokenKey);
  }
  return new Promise((resolve, reject) => {
    resolve(true);
  });
  // return api.post(`/json/staging.AbandonedCart?CartId=${cartId}`, null);
};

/*
	integrateAddress(userId) {
		return api.post(`/json/D365CustomerRegistration?UserId=${userId}`);
	}
    */

export const register = (
  newUser: any,
): Promise<RegistrationSuccessEvent | RegistrationFailEvent> => axios.post('api/v1/account/register', newUser);

export const forgotPassword = (email: string): Promise<null> => axios.delete('api/v1/user/forgotPassword', { data: { email } });

export const resetPassword = (
  email: string,
  resetToken: string,
  password: string,
): Promise<any> => axios.put('api/v1/user/forgotPassword', {
  email,
  resetToken,
  password,
});

/**
 * For change password we are using api wrapper around axios as we need
 * to add the token to the request to make sure the user is authenticated.
 */
export const changePassword = (
  userId: string,
  currentPassword: string,
  newPassword: string,
): Promise<ChangePasswordResponse> => api.put(`/api/v1/user/${userId}/changePassword`, {
  currentPassword,
  newPassword,
});
