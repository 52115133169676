import React, { ChangeEvent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  dispatchKeyword: (keyword: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginRight: '1rem',
  },
  buttonWrapper: {
    marginTop: '1rem',
  },
}));

/**
 * Generic component to handle user search by keyword
 * @param dispatchKeyword Function to propagate keyword to parent component
 * @constructor
 */
const KeywordSearch: React.FC<Props> = ({ dispatchKeyword }) => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState<string>('');

  /**
   * Handles the component state keyword
   * @param event Change event for the text field
   */
  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };
  /**
   * Propagates the keyword search to parent component
   */
  const handleKeywordSubmit = (): void => {
    dispatchKeyword(keyword);
  };
  /**
   * Enables the use of enter button to search
   * @param event Keyboard event for the text field
   */
  const handleKeyup = (event: any) => {
    if (event.keyCode === 13) {
      handleKeywordSubmit();
    }
  };

  /**
   * Clears keyword, has to dispatch its own event as the setState is async
   * This means that if we used the submit function here, it would still contain the keyword
   */
  const handleKeywordClear = () => {
    setKeyword('');
    dispatchKeyword('');
  };
  return (
    <>
      <TextField
        id="search-accounts"
        label="Search Accounts"
        placeholder="Search by Account Name or Account Code"
        value={keyword}
        onChange={handleKeywordChange}
        onKeyUp={handleKeyup}
      />
      <Grid container justifyContent="flex-end" className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          onClick={handleKeywordClear}
          variant="contained"
          color="default"
        >
          Clear
        </Button>
        <Button
          className={classes.button}
          onClick={handleKeywordSubmit}
          variant="contained"
          color="primary"
        >
          Search
        </Button>
      </Grid>
    </>
  );
};

export default KeywordSearch;
