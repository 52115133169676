import { gql, useMutation } from '@apollo/client';

export const SET_PATIENT_CHART_DISCONNECT_REASON = gql`
  mutation setPatientChartDisconnectReason($input: ConnectionInfo) {
    setPatientChartDisconnectReason(input: $input) {
      message
    }
  }
`;

export const useSetPatientChartDisconnectReason = () => {
  const [mutate, { data, error }] = useMutation(SET_PATIENT_CHART_DISCONNECT_REASON);

  return { mutate, data, error };
};
